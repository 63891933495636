import { Fragment, memo, useEffect, useState } from 'react';
import { decode } from 'html-entities';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Script from "next/script";
import Head from 'next/head';
import key from 'weak-key';
import { getVideoKeywords, formatVideoMetaDataFromShortCode, css2obj } from '~/utils/utils';
import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import ParallaxImage from '~/components/parallaxImage/parallaxImage';
import SiteSettings from '~/siteSettings';
import LinkTracker from '~/components/linkTracker/linkTracker';

const EmbedTweet = dynamic(() => import('~/components/embedTweet/embedTweet'), { ssr: false });
const EmbedIg = dynamic(() => import('~/components/embedIg/embedIg'), { ssr: false });
const EmbedTikTok = dynamic(() => import('~/components/embedTiktok/embedTiktok'), { ssr: false });
const EmbedFacebook = dynamic(() => import('~/components/embedFacebook/embedFacebook'), { ssr: false });
const EmbedYoutube = dynamic(() => import('~/components/embedYoutube/embedYoutube'), { ssr: false });
const CmvAvia = dynamic(() => import('@cbsi-ctd/cmv_avia').then((mod) => mod.Player), { ssr: false });



/**
 * Body Parser
 * @category Functional Component
 * @extends Component
 * @summary Parses a JSON encoded body field.
 * @prop {Object} content - The body content.
 * @prop {String} baseDomain - The base domain.
 * @prop {bool} adsEnabled - Are ads enabled?
 * @prop {Object} drupalSettings - The drupal settings.
 * @return {Node | JSX.Element} React node containing the parsed body field.
 */

const BodyParser = ({ content, baseDomain, adsEnabled, drupalSettings }) => {
  const parsed = JSON.parse(content);
  const [allowSocialEmbeds, setAllowSocialEmbeds] = useState(false);
  const articleNodeId = content?.nid;
  let injectCount = 0;
  let incrementalCount = 0;

  useEffect ( () => {
    if(typeof cbsoptanon !== 'undefined' && cbsoptanon !== null) {
      cbsoptanon?.cmd?.push(function(cmp) {
        cmp.ot.socialAllowed(async function(allowed) {
          if (allowed) {
            setAllowSocialEmbeds(true);
          }
        });
        cmp.ot.addOnConsentChangedHandler(function() {
          cmp.ot.socialAllowed(async function(allowed) {
            if (allowed) {
              setAllowSocialEmbeds(true);
            }
          });
        });
      });
    }
  }, []);

  // These variables are used for ad placement
  let paragraphCount = 0;
  let wordCount = 0;
  let incrementalAdCount = 0;
  let midAdSet = false;
  
  const getTag = (elem) => {
    switch (elem.element.tag) {
      case 'div':
        paragraphCount += 1;
        wordCount += 50;
        return getShortcode({
          allowSocialEmbeds,
          articleNodeId,
          baseDomain,
          elem: elem.element.value,
          SiteSettings
        });
      case 'a':
        const attributes = elem.element.attr.class 
          ? { ...elem.element.attr, className: elem.element.attr.class }
          : { ...elem.element.attr }
        delete attributes.class;  
        return (
          <a
            key={key(elem)}
            {...attributes}
            dangerouslySetInnerHTML={{ __html: elem.element.value }}
          />
        );
      case 'br':
      case 'hr':
        return <elem.element.tag key={key(elem)} />
      case 'img':
      case 'meta':
        return <elem.element.tag {...elem.element.attr} key={key(elem)} />
      case 'iframe':
        const css_object = elem?.element?.attr?.style ? css2obj(elem.element.attr.style) : null;
        
        if (css_object) {
          return <elem.element.tag src={elem.element.attr.src} scrolling={elem.element.attr.scrolling} style={css_object} key={key(elem)} />
        }

        return <elem.element.tag src={elem.element.attr.src} scrolling={elem.element.attr.scrolling} key={key(elem)} />
      case 'p':
        paragraphCount += 1;
      default:
        return (
          <elem.element.tag key={key(elem)}>
            {getChildren(elem.element.value)}
          </elem.element.tag>
        );
    }
  }

  const getChildren = (children) => {
    if (!children.map) {
      console.error('[BODY PARSER: could not map children in "getChildren"]', children);
      return <span />;
    }

    return children.map((child) => {
      if (child.type === 'text') {
        wordCount += child.element.value.split(' ').length;
        return <span key={key(child)} dangerouslySetInnerHTML={{ __html: child.element.value }} />
      } else {
        return getTag(child);
      }
    });
  }

  const getMarkup = () => parsed.map((elem, i) => {
    const output = [];
    output.push(getTag(elem));
    
    // Ads
    if(adsEnabled) {
      let isInBetween = false;
      let justInjected = false; // Don't inject next to each other

      // Only inject between P tags
      if(parsed[i - 1] && parsed[i + 1]) {
        isInBetween = parsed[i].type === 'tag' && parsed[i].element.tag === 'p'
          && parsed[i + 1].type === 'tag' && parsed[i + 1].element.tag === 'p'
      }

      // Inject after 2 paragraphs, only once
      if(paragraphCount > 2 && injectCount === 0 && isInBetween) {
        injectCount += 1;
        justInjected = true;
        output.push(
          <Fragment key={ uuidv1() }>
            <div
              data-ad-ref='leader-plus-flex-top'
              className='bidbarrel-ad body-ad'
            />
            <div
              data-ad-ref='mobile-plus-flex-top'
              className='bidbarrel-ad body-ad'
            />
          </Fragment>
        );
      }

      // Inject after every 3 paragraphs, only once
      if(paragraphCount % 3 === 1 && injectCount === 1 && isInBetween && !justInjected) {
        injectCount += 1;
        justInjected = true;
        output.push(
          <Fragment key={ uuidv1() }>
            <div
              data-ad-ref='leader-plus-flex-middle'
              className='bidbarrel-ad body-ad'
            />
            <div
              data-ad-ref='mobile-plus-flex-middle'
              className='bidbarrel-ad body-ad'
            />
          </Fragment>
        );
      }

      // Inject after every 3 paragraphs
      if(paragraphCount % 3 === 1 && injectCount >= 2 && isInBetween && !justInjected) {
        injectCount += 1;
        incrementalCount += 1;
        output.push(
          <Fragment key={ uuidv1() }>
            <div
              data-ad-ref='leader-plus-flex-inc'
              data-incremental={incrementalCount}
              className='bidbarrel-ad body-ad'
            />
            <div
              data-ad-ref='mobile-plus-flex-inc'
              data-incremental={incrementalCount}
              className='bidbarrel-ad body-ad'
            />
          </Fragment>
        );
      }
    }

    return output;
  });

  const getPicture = (renditions, shortCodeElement, className) => {
    return(
      <picture>
        { Object.entries(renditions[0]).map((rendition, i) => {
          // First Source is Fallback
          if(i === 0) {
            return(
              <img
                key={`fallback-${key(shortCodeElement)}`}
                src={ rendition[1].url }
                width={ rendition[1].width }
                height={ rendition[1].height }
                alt={ shortCodeElement?.alt }
                className={ className }
              />
            );
          }

          return(
            <source
              key={`source-${key(shortCodeElement)}-${ Math.floor(Math.random() * 1000) + 1 }`}
              media={`(max-width: ${ rendition[1].width }px)`}
              srcSet={`${ baseDomain }${ rendition[1].url }`}
            />
          );
        })}
      </picture>
    );
  }

  const getShortcode = ({ elem, i }) => {
    // Skip any errors if we cannot assign shortcode
    if(!elem.element) {
      return false;
    }
  
    const shortCodeElement = elem.element;
    const shortCodeType = shortCodeElement['shortcode-id'];
  
    // HTML
    if(shortCodeType === 'html') {
      const shortCodeHtml = shortCodeElement['shortcode-html'].includes('sideqik')
            ? decode(shortCodeElement['shortcode-html'])
            : _.unescape(shortCodeElement['shortcode-html']);
      return(
        <div key={ shortCodeElement.id } dangerouslySetInnerHTML={{ __html:  shortCodeHtml }} />
      );
    }
  
    // Image
    if(shortCodeType === 'image') {
      const { images } = shortCodeElement;
      paragraphCount += 1;
  
      if(shortCodeElement['view-mode'] === 'animated_gif') {
        return(
          <div key={`elem-${key(shortCodeElement)}`} className='shortcode-animatedgif'>
            <img
              className='img-responsive'
              src={ shortCodeElement?.images?.default?.url }
              alt={ shortCodeElement?.alt }
            />
          </div>
        );
      }
  
      if(shortCodeElement['view-mode'] === 'content_in') {
        return(
          <figure key={`elem-${key(shortCodeElement)}`} className='shortcode-image'>
            { getPicture(images, shortCodeElement, 'img-full') }
            { shortCodeElement.caption &&
              <figcaption>{ shortCodeElement.caption }</figcaption>
            }
          </figure>
        );
      }
  
      return(
        <figure key={`elem-${key(shortCodeElement)}`} className='shortcode-image'>
          <ParallaxImage>
            { getPicture(images, shortCodeElement, 'parallax-image__visible') }
          </ParallaxImage>
          { shortCodeElement.caption &&
            <figcaption>{ shortCodeElement.caption }</figcaption>
          }
        </figure>
      );
    }
  
    // Speedbump
    if(shortCodeType === 'speedbump') {
      return(
        <div className='shortcode-speedbump' key={`elem-${key(shortCodeElement)}`}>
          <Link
            href={ shortCodeElement.url }
          >
            <a
              target={ shortCodeElement['shortcode-new-window'] === 0 ? '_self' : '_blank' }
              rel='noopener noreferrer'
            >
              Related: { shortCodeElement['shortcode-title'] }
            </a>
          </Link>
        </div>
      );
    }

    let continuousPlay = 0;
    const onContentComplete = () => {
      continuousPlay = 1;
    }

    // Video
    if(shortCodeType === 'video') {
      if (shortCodeElement?.video?.hls_streaming_url) {
        return (
            <div className='shortcode-video' key={`elem-${key(shortCodeElement)}`}>
              <CmvAvia
                  autoplay="none"
                  captions={shortCodeElement.field_caption
                      ? `/vtt?file=${shortCodeElement.field_caption}`
                      : null
                  }
                  compactViewBreakpoint="480"
                  continuousPlay={continuousPlay}
                  embedded
                  fms={drupalSettings?.videoSettingsQuery?.enable_fms}
                  hideAds={parseInt(shortCodeElement.isPromotional, 10)}
                  keywords={getVideoKeywords(shortCodeElement)}
                  mediaContentType="vod"
                  playlistArr={[formatVideoMetaDataFromShortCode(articleNodeId, shortCodeElement.video, shortCodeElement)]}
                  siteSettings={SiteSettings}
                  useReplay
                  useUnmuteCta
                  vidPlayerPos="body"
              />
            </div>
        );
      }
    }

  
    // Tweet / Social Embed
    if(shortCodeType === 'social-media') {
      const platform = shortCodeElement['shortcode-url'].match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g);
      paragraphCount += 1;
  
      // TikTok
      if(platform[0] === 'www.tiktok.com' || platform[0] === 'tiktok.com') {
        return (
          <Fragment key={`elem-${key(elem)}`}>
            <Head>
              <script key='tiktok-script' async='' src='https://www.tiktok.com/embed.js'/>
            </Head>
            <EmbedTikTok url={ shortCodeElement['shortcode-url'] } />
          </Fragment>
        );
      }
  
      // Facebook
      if (platform[0] === 'www.facebook.com' || platform[0] === 'facebook.com') {
        return (
          <Fragment key={`elem-${key(elem)}`}>
            <Head>
              <script
                key='facebook-script'
                async
                defer
                src='https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2'
                className='optanon-category-5'
              />
            </Head>
            <EmbedFacebook
              key={`elem-${key(elem)}`}
              url={ shortCodeElement['shortcode-url'] }
            />
          </Fragment>
        );
      }
  
      // Instagram
      if(platform[0] === 'www.instagram.com' || platform[0] === 'instagram.com') {
        return (
          <Fragment key={`elem-${key(elem)}`}>
            <Script
              id='ig-script'
              key='ig-script'
              src='https://platform.instagram.com/en_US/embeds.js'
              type='text/plain'
              className='optanon-category-5'
            />
            <EmbedIg url={ shortCodeElement['shortcode-url'] } />
          </Fragment>
        );
      }
  
      // Youtube
      if (platform[0] === 'www.youtube.com' || platform[0] === 'youtube.com' || platform[0] === 'youtu.be') {
        return (
          <EmbedYoutube key={`elem-${key(elem)}`} url={ shortCodeElement['shortcode-url'] } />
        )
      }
  
      // Twitter
      if(platform[0] === 'twitter.com') {
        return (
          <Fragment key={`elem-${key(elem)}`}>
            <Head>
              <script key='twitter-script' dangerouslySetInnerHTML={{ __html: `
                window.twttr = (function(d, s, id) {
                  var js, fjs = d.getElementsByTagName(s)[0],
                    t = window.twttr || {};
                  if (d.getElementById(id)) return t;
                  js = d.createElement(s);
                  js.id = id;
                  js.src = "https://platform.twitter.com/widgets.js";
                  fjs.parentNode.insertBefore(js, fjs);
  
                  t._e = [];
                  t.ready = function(f) {
                    t._e.push(f);
                  };
  
                  return t;
                }(document, "script", "twitter-wjs"));
              `}} />
            </Head>
            <div className='shortcode-twitter'>
              <EmbedTweet
                key={`elem-${key(shortCodeElement)}`}
                url={ shortCodeElement['shortcode-url'] }
              />
            </div>
          </Fragment>
        );
      }
    }
  
    // Listicles
    if(shortCodeType === 'listicle') {
      return(
        <div className='shortcode-listicle' key={`elem-${key(shortCodeElement)}`}>
          <div className='listicle-header'>
            <h2>{ shortCodeElement.title }</h2>
            <div dangerouslySetInnerHTML={{__html: shortCodeElement.description}}/>
          </div>
          <div className='listicle-button'>
            <div className="listicle-button-body">
              <div>
                <img
                  className='listicle-img'
                  src={ shortCodeElement.image[0]['275x275'].url }
                  width={ shortCodeElement.image[0]['275x275'].width }
                  height={ shortCodeElement.image[0]['275x275'].height }
                  alt={ shortCodeElement?.image?.alt }
                  rel='sponsored'
                /><br />
                <div className='brand-listicle'>{shortCodeElement.brand}</div>
              </div>
              <div>
                <div className='listicle-header listicle-header--desktop'>
                  <h2>{ shortCodeElement.title }</h2>
                  <div dangerouslySetInnerHTML={{__html: shortCodeElement.description}}/>
                </div>
                { Object.entries(shortCodeElement.buttons).map((button) => {
                  return(
                    <div key={`listicle-${key(shortCodeElement.buttons)}`}>
                      <p>{ button[1].cta }</p>
                      <span className='listicle-button-title'>{ button[1].title }</span>
                      <LinkTracker
                        destinationUrl={ button[1].link || shortCodeElement.link }
                        linkCta={ button[1].text.toLowerCase() }
                        linkFormat='button'
                        linkRegion='body'
                        linkSubRegion='ecom'
                        leadStore={ button[1].store }
                      >
                        <a
                          className ='btn btn--brand btn--listicle'
                          href = { button[1].link || shortCodeElement.link }
                          target={ shortCodeElement['shortcode-new-window'] === 0 ? '_self' : '_blank' }
                          rel='noopener noreferrer sponsored'
                          data-autotrack='false'
                        >
                          <span>{ button[1].text }</span>
                        </a>
                      </LinkTracker>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  
    return false;
  }

  return (
    <div className='ctd-body'>
      <div data-ad-ref="mobile-incontent-social" className="mobile-incontent-social bidbarrel-ad" aria-hidden />
      { getMarkup() }
    </div>
  );
}

export default memo(BodyParser);
